<template>
  <div>
    <b-card
        text-variant="center"
        class="card card-header">
      <b-avatar
          variant="primary"
          size="70"
          class=" mb-2 circle-icon ">
        <feather-icon
            size="28"
            icon="AwardIcon"
            class="award"
        />
      </b-avatar>
      <h1 class="mb-1 mt-50 text-white" v-if="userData">
        {{ $t('Welcome') }} {{ userData.profile.firstname }}!
      </h1>
    </b-card>

    <statistic-boxes :title="$t('Statistics')" :data="data" />

    <b-row>
      <b-col md="6">
        <transactions :data="data"/>
      </b-col>
      <b-col md="6">
        <links :data="data"/>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BAlert,
  BAvatar,
  BBadge, BButton, BCard, BCardText,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect, BImg,
  BInputGroup, BInputGroupAppend, BOverlay,
  BPagination,
  BRow,
  BTable
} from "bootstrap-vue";
import vSelect from "vue-select";
import Earnings from "@/views/dashboard/parts/Earnings";
import Balance from "@/views/dashboard/parts/Balance";
import StatisticBoxes from "@/views/dashboard/parts/StatisticBoxes";
import Links from "@/views/dashboard/parts/Links";
import Transactions from "@/views/dashboard/parts/Transactions";

export default {
  components: {
    StatisticBoxes,
    Earnings,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,
    BAlert,
    BCardText,
    BImg,
    vSelect,
    Balance,
    Transactions,
    Links,
  },
  data() {
    return {
      data: null,
      userData: null,
    }


  },
  methods: {
    getData() {
      this.$http.get("affiliates/dashboard").then((response) => {
        this.data = response.data
        this.$emit("update:data", response.data);
      });
    },
  },
  mounted() {
    this.getData()

    this.userData = JSON.parse(window.localStorage.getItem('userData'))
  }


}
</script>
<style>
.circle-icon {
  background-color: rgb(127, 177, 176);
  box-shadow: 0 4px 29px 0 rgb(34 41 47 / 50%) !important;;
}

.card-header {
  background: #ad8a60;
}
[dir] .card .card {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
}
</style>

