<template>
  <div>
    <b-card :title="$t('Balance')">

    </b-card>
  </div>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BCard,
  },
  data() {
    return {
    };
  },
  methods: {
    ...mapActions(['GET_BALANCE']),
  },
  computed: {
    ...mapGetters(['BALANCE']),
  }
};
</script>
