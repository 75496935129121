<template>
  <div>
    <b-overlay :show="!data" rounded="sm">
      <b-card no-body class="card-statistics" :title="title">
        <b-card-header>
          <b-card-title>{{ title }}</b-card-title>
          <b-card-text class="font-small-2 mr-25 mb-0">
          </b-card-text>
        </b-card-header>
        <b-card-body v-if="data" class="statistics-body">
          <b-row>
            <b-col v-for="item in data.statistics_boxes" :key="item.subtitle" xl="3" sm="6" :class="item.customClass">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" :variant="item.color">
                    <feather-icon size="24" :icon="item.icon"/>
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ item.title }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t(item.subtitle) }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BMediaBody,
  BMediaAside,
  BMedia,
  BAvatar,
  BCardText,
  BCardTitle,
  BOverlay
} from "bootstrap-vue";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    BAvatar,
    BRow,
    BCol,
    BCard,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardText,
    BOverlay,
  },
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      default: () => "",
    },
    data: {
      type: Object,
      default: () => [],
    },
  },
};
</script>
