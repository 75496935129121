<template>
  <div>
    <b-overlay :show="!data" rounded="sm">
      <b-card :title="$t('Affiliate links')">
        <b-form-group
            :label="$t('Expert')"
        >
          <b-input-group v-if="data">
            <b-form-input
                :placeholder="$t('Expert affiliate link')"
                v-model="data.affilate_links.expert"
                @focus="$event.target.select()"
            />
            <b-input-group-append>
              <b-button
                  variant="success"
                  v-clipboard:copy="data.affilate_links.expert"
                  v-clipboard:success="onLinkCopy"
              >
                <feather-icon icon="CopyIcon"/>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group
            :label="$t('Client')"
        >
          <b-input-group v-if="data">
            <b-form-input
                :placeholder="$t('Client affiliate link')"
                v-model="data.affilate_links.client"
                @focus="$event.target.select()"
            />
            <b-input-group-append>
              <b-button
                  variant="success"
                  v-clipboard:copy="data.affilate_links.client"
                  v-clipboard:success="onLinkCopy"
              >
                <feather-icon icon="CopyIcon"/>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BButton,
  BFormGroup,
  BOverlay
} from "bootstrap-vue";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    BRow,
    BButton,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
  },
  props: {
    data: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(['GET_BALANCE']),

    onLinkCopy() {
      this.$bvToast.toast(this.$i18n.t('The link was copied to the clipboard'), {
        title: this.$i18n.t('Success'),
        variant: 'success',
        toaster: 'b-toaster-bottom-right'
      })
    },
    selectLink() {
      this.$refs.access_token_input.select();
    },
  },
  computed: {
    ...mapGetters(['BALANCE']),
  }
};
</script>
