<template>
  <b-card >
    <b-overlay
        variant="white"
        rounded="sm"
        :show="!loaded">
      <div class="earnings d-flex justify-content-between align-items-center">
        <div class="info_side pr-2">
          <b-card-text class="font-medium-5">
            {{ $t('Earnings')}}
          </b-card-text>
          <b-card-text class="font-small-4 mb-0  text-muted">
            {{ $t('This month') }}
          </b-card-text>
          <b-card-text class="font-medium-2 pb-25 font-weight-normal">
            {{ monthsCredits }} {{ $t('Credits') }}
          </b-card-text>
          <b-card-text class=" pb-25 font-weight-normal ">
            {{ earningsPrecent}}% <span class="text-muted font-small-5">{{ $t('More earnings than last month') }} </span>
          </b-card-text>
        </div>
        <div class="info_chart">
          <b-card-text class=" pb-25 font-weight-normal ">
            {{ earningsPrecent}}% <span class="text-muted font-small-5">{{ $t('Earnings') }} </span>
          </b-card-text>
          <chartjs-component-doughnut-chart
              :height="100"
              :width="100"
              :data="doughnutChart.data"
              :options="doughnutChart.options"

          />
        </div>
      </div>
    </b-overlay>
  </b-card>
</template>
<script>
import {
  BButton,
  BCard,
  BOverlay,
  BProgress,
  BCardText

} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ChartjsComponentDoughnutChart from '@core/components/charts/ChartjsComponentDoughnutChart.vue'
export default {
  name: "UpcomingEvents",
  components: {
    BCard,
    BOverlay,
    BButton,
    BProgress,
    BCardText,
    ChartjsComponentDoughnutChart,
  },
  data() {
    return {
      loaded: true,
      monthsCredits: 5600,
      earningsPrecent: 68,
      doughnutChart: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          cutoutPercentage: 60,
          legend: { display: false },
          tooltips: {
            callbacks: {
              label(tooltipItem, data) {
                const label = data.datasets[0].labels[tooltipItem.index] || ''
                const value = data.datasets[0].data[tooltipItem.index]
                const output = ` ${label} : ${value} %`
                return output
              },
            },
          },
        },
        data: {
          datasets: [
            {
              labels: ['Last month', 'Current month'],
              data: [10, 90],
              backgroundColor: ['#86afae','#207776'],
              borderWidth: 0,
              pointStyle: 'rectRounded',
            },
          ],
        },
      },
    }
  }
}
</script>
<style scoped lang="scss">

</style>
