<template>
  <div>
    <b-card :title="$t('Last 5 transactions')">
      <b-overlay :show="!data" rounded="sm">
        <b-table v-if="data" ref="refTransactionsListTable" class="position-relative" responsive primary-key="id"
                 :items="data.transactions" :fields="tableColumns" show-empty
                 :empty-text="$t('No matching records found')"
        >
          <template #head()="data">
            {{ $t(data.label) }}
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button v-on:click="deleteItem(data.item.id)" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      type="submit" size="sm"
            >
              {{ $t('Delete') }}
            </b-button>
          </template>
        </b-table>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {BRow, BCol, BCard, BTable, BOverlay} from "bootstrap-vue";
import {mapActions, mapGetters} from "vuex";
import moment from "moment";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BOverlay,
  },
  props: {
    data: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {
      tableColumns: [
        {
          key: 'created_at',
          sortable: true,
          label: 'Created at',
          formatter: (value) => {
            return moment(String(value)).format('DD.MM.YYYY hh:mm:ss')
          }
        },
        {
          key: 'amount',
          sortable: true,
          label: 'Amount in EUR',
        },
      ],
    };
  },
  methods: {
    ...mapActions(['GET_BALANCE']),
  },
  computed: {
    ...mapGetters(['BALANCE']),
  }
};
</script>
